import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Article = ({ children }) => (
  <dl className={styles.Documentation_Article}>{children}</dl>
)

/**
 */
export const ArticleTitle = ({ children }) => (
  <dt className={styles.Documentation_Article_Title}>{children}</dt>
)

/**
 */
export const ArticleBody = ({ children }) => (
  <dd className={styles.Documentation_Article_Body}>{children}</dd>
)

/**
 */
export const ParagraphList = ({ children }) => (
  <ol className={styles.Documentation_ParagraphList}>{children}</ol>
)

/**
 */
export const Paragraph = ({ children }) => (
  <li className={styles.Documentation_ParagraphList_Item}>{children}</li>
)

/**
 */
export const ItemList = ({ children }) => (
  <ol className={styles.Documentation_ItemList}>{children}</ol>
)

/**
 */
export const Item = ({ children }) => (
  <li className={styles.Documentation_ItemList_Item}>{children}</li>
)

/**
 *
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Header = ({ children }) => (
  <p className={styles.Header}>{children}</p>
)

/**
 *
 */
export const Footer = ({ children }) => (
  <h3 className={styles.Footer}>{children}</h3>
)

export const Title = ({ children }) => (
  <div className={styles.Title}>
    <h3 className={styles.Title_Text}>{children}</h3>
  </div>
)
