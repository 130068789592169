import React from 'react'

/**
 */
export const Language = ({ language }) => {
  return (
    <>
      <ContentLanguage language={language} />
      <meta name="google" content="notranslate" />
    </>
  )
}

const ContentLanguage = ({ language }) => {
  if (!language) {
    return null
  }

  return <meta httpEquiv="Content-Language" content={language} />
}
