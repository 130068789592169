import React from 'react'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

import { Content as ContentJa } from './Content.ja'
import { Content as ContentEn } from './Content.en'

/**
 */
export const Content = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'ja':
      return <ContentJa {...props} />
    case 'en':
    default:
      return <ContentEn {...props} />
  }
})
