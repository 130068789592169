// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--R9DZu";
export var Documentation_Article = "styles-module--Documentation_Article--jDPuk";
export var Documentation_Article_Title = "styles-module--Documentation_Article_Title--k3aJ7";
export var Documentation_ItemList = "styles-module--Documentation_ItemList--udt8k";
export var Documentation_ItemList_Item = "styles-module--Documentation_ItemList_Item--K4A7p";
export var Documentation_ParagraphList = "styles-module--Documentation_ParagraphList--YDZ94";
export var Documentation_ParagraphList_Item = "styles-module--Documentation_ParagraphList_Item--I6yKk";
export var Footer = "styles-module--Footer--sAbNW";
export var Title = "styles-module--Title--u66v6";
export var Title_Text = "styles-module--Title_Text--KUhcH";