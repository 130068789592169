import React from 'react'

import * as styles from './styles.module.scss'

/**
 *
 */
export const ContentsTitle = ({ children }) => {
  return <h2 className={styles.ContentsTitle}>{children}</h2>
}

/**
 *
 */
export const StaticSectionTitle = ({ children }) => {
  return <div className={styles.StaticSectionTitle}>{children}</div>
}

/**
 *
 */
export const StaticPageTitle = ({ children }) => {
  return <h2 className={styles.StaticPageTitle}>{children}</h2>
}
