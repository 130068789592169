import React from 'react'

import { Context as LanguageContext } from './Languages/Context'

/**
 */
export const withLanguage = (Component) => (props) => {
  const { language, languages } = React.useContext(LanguageContext)

  return <Component language={language} languages={languages} {...props} />
}
